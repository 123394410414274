<template>
  <v-row no-gutters>
    <HbPropertySelector
      id="property"
      :items="facilityList"
      item-text="name"
      return-object
      v-validate="'required'"
      v-model="propertySelected"
      data-vv-name="property"
      data-vv-as="Property"
      :error="errors.has('property')"
    />
  </v-row>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";

export default {
  name: 'PropertySelector',
  data() {
    return {};
  },
  components: {},
  props: {
    property: {
      type: Object,
      default: null
    },
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
    }),
    isPropertySelected() {
      return this.property && this.property.id ? true : false;
    },
		propertySelected: {
			get: function() {
				return this.property;
			},
			set: function(value) {
				this.$emit('propertyChanged', value);
			}
		}
  }
};
</script>
